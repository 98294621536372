import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../../sass/blocks/pieces-per-collection.scss';
import _ from 'lodash';
import Img from 'gatsby-image';

export default class PiecesPerCollectionLightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      images: [],
      imagesFluid: []
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { edges } = nextProps.pieces;
    const imagesFluid = _.map(edges, image => {
      return image.node.frontmatter.img.childImageSharp.fluid 
    });    
    const images = _.map(edges, image => {
      return image.node.frontmatter.img.childImageSharp.fluid.src
    });
    return {
      images,
      imagesFluid
    }
  }
  renderImgs() {
    return _.map(this.state.imagesFluid, (image, i) => {
      return (
        <li>
          <a 
            onClick={() => this.setState({ isOpen: true, photoIndex: i })}
            >
          <Img fluid={image} />
          </a>
        </li>
      )
    });    
  }  
  render() {
    const { photoIndex, isOpen, images } = this.state;
    return (
      <div>
      <ul className='images-container'>
        {this.renderImgs()}
      </ul>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
