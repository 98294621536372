import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import PiecesPerCollection from "../components/collections/piecesPerCollection";
import PiecesPerCollectionLightbox from "../components/collections/piecesPerCollectionLightbox";

import "../sass/layout/collection.scss";

export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const {
    frontmatter: { title, subtitle, date, img },
    html
  } = markdownRemark;

  return (
    <div className="wrapper-main layout-collection">
      <div className="headline">
        <h1>{title}</h1>
        <h3>{date}</h3>
      </div>
      <div className="main-img">
        {img ? <Img sizes={img.childImageSharp.fluid} /> : null}
      </div>
      <div className="content">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div className="pieces-per-collection">
        <div className="container">
          <PiecesPerCollectionLightbox pieces={data.pieces} />
        </div>
      </div>
    </div>
  );
}

export const collectionQuery = graphql`
  query($path: String!, $collection: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        materials
        subtitle
        date(formatString: "YYYY")
      }
    }
    pieces: allMarkdownRemark(
      filter: {
        frontmatter: { collection: { eq: $collection }, type: { eq: "piece" } }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            type
            collection
            path
            title
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
